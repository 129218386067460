/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import { Box, Flex, Heading, LightMode, Link } from '@chakra-ui/react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import zach from './../TRABlog/ZachMarks.png'
import BlogAuthor from '../../BlogAuthor'
import { BlogImage } from '../TRABlog'
import oktaOneHourEnabled from './okta_1_hour_enabled.png'
import oktaOneHourDeleted from './okta_1_hour_deleted.png'
import oktaTraControlDoneNoRisk from './okta_tra_control_done_no_risk.png'
import oktaTraControlModifyNorisk from './okta_tra_control_modify_no_risk.png'
const ConfigurationDriftBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                The Fast and the Furious – Configuration Drift
              </Heading>
              <Flex py="8px" alignItems="center" justifyContent="space-around">
                <Box fontSize="12px">August 29, 2024</Box>
                <BlogAuthor name="Zach Marks" img={zach} />
                <Box fontSize="12px">5 minute read</Box>
              </Flex>
              <Box mb="16px">
                The relationship between IT and security runs deep. IT focuses
                on enhancing business operations by optimizing processes and
                improving efficiency, while security implements protective
                measures across the network to ward off threats.
              </Box>
              <Box mb="16px">
                As organizations expand and evolve, the relationship between the
                two becomes equally as important as it is complex. The rapid
                pace of technology changes to drive revenue and streamline
                operations, coupled with an increasing number of sophisticated
                cyber threats, forces both IT and security teams to constantly
                adapt. This dynamic environment makes it challenging to maintain
                the integrity of security controls while ensuring operational
                efficiency and availability. For security teams, this challenge
                is especially evident when it comes to monitoring and
                maintaining established security controls from a configuration
                lens.
              </Box>

              <Heading size="md" mb="16px">
                Security Prioritizes Controls; IT Prioritizes Availability
              </Heading>

              <Box mb="16px">
                Security teams invest significant effort into deploying measures
                that protect users, networks, and infrastructure through a wide
                array of enterprise security products. However, there are
                instances where these measures may inadvertently impact business
                critical functions, and the deployed controls must be modified.
                For example, if a security policy causes a key executive to lose
                access to essential resources, IT (or a security colleague)
                might disable the control to restore access as soon as possible.
                While this achieves IT’s goal of maintaining availability, it
                also removes a safeguard designed to protect against potential
                threats. Meanwhile, the security team remains unaware that the
                control has been removed, and this lack of knowledge compounds
                with scale.
              </Box>

              <Heading size="md" mb="16px">
                The Importance of Monitoring for Control Drift: A Practical
                Example
              </Heading>

              <Box mb="64px">
                Consider a scenario where a security engineer implements a new
                sign-on rule in Okta, designed to log out highly targeted users,
                including the CFO, after one hour if they are logging in from a
                new device.
              </Box>

              <BlogImage src={oktaOneHourEnabled} />
              <Box fontStyle="italic" mb="64px">
                One hour session timeout for Most Attacked users, including the
                CFO.
              </Box>

              <Box mb="64px">
                If the CFO, using a new laptop, is timed out while working on
                sensitive financial data, IT might disable the policy to prevent
                future interruptions, especially when the ask is coming from the
                c-suite.
              </Box>

              <BlogImage src={oktaOneHourDeleted} />
              <Box fontStyle="italic" mb="64px">
                The policy is deleted on the spot without vetting to maintain
                availability.
              </Box>

              <Box mb="16px">
                From a security perspective, this eliminates a critical control
                intended to address the downstream impact of credential
                compromise, as attackers will almost always be attempting login
                from a new device after getting a hold of credentials. To
                address this, security teams must continuously monitor
                configurations and controls to detect any deviations from the
                established baseline and take action when drift is detected.
              </Box>

              <Heading size="md" mb="16px">
                Enter Reach
              </Heading>

              <Box mb="64px">
                Beyond making recommendations, Reach creates an inventory of the
                configurations that have been deployed into your security
                products and tracks these deployments for changes over time.
              </Box>

              <BlogImage src={oktaTraControlDoneNoRisk} />
              <Box fontStyle="italic" mb="64px">
                Reach provides visibility into deployed sign-on rules that are
                marked as done.
              </Box>

              <BlogImage src={oktaTraControlModifyNorisk} />
              <Box fontStyle="italic" mb="64px">
                Reach detects drift and alerts that controls that were
                previously enabled are no longer enabled.
              </Box>

              <Box mb="64px">
                This allows you to proactively understand when changes to
                controls outside of your direct purview have been made, allowing
                you to act accordingly while maintaining a healthy level of
                availability without sacrificing security.
              </Box>

              <Heading size="md">More About Reach Security</Heading>
              <br />
              <Box>
                Reach is the first AI purpose-built to reprogram your security
                infrastructure based on who you are and how you're being
                attacked. Organizations of all sizes trust Reach to make
                mission-critical decisions because it doesn't hallucinate and it
                doesn't make mistakes. It's a different kind of AI. To learn
                more, visit{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://reach.security/try-reach"
                >
                  reach.security/try-reach
                </Link>
                .
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default ConfigurationDriftBlog
